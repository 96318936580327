import React from 'react'
import { t } from '@lingui/macro'
import { useHistory } from 'react-router-dom'

import PageContainer from '../common/PageContainer'
import { IconTextButton } from '../common/buttons'
import BackIcon from '../../assets/Back.svg'
import { Heading1 } from '../common/typography'

import LanguagePicker from './LanguagePicker'
import Column from '../common/Column'

const Settings = () => {
  const history = useHistory()

  const goToMenu = () => history.goBack()

  return (
    <PageContainer>
      <Column alignItems="center">
        <Column alignItems="flex-start" style={{ maxWidth: 700 }}>
          <IconTextButton label={t`Takaisin`} onClick={goToMenu} icon={<BackIcon />} strokeColor />
          <Heading1>{t`Asetukset`}</Heading1>
          <LanguagePicker />
          {/* TODO: :( */}
          {/* <QuestionHighlights /> */}
        </Column>
      </Column>
    </PageContainer>
  )
}

export default Settings
