import { t } from '@lingui/macro'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setLang } from '../../redux/reducers/settings'
import { ToggleButton } from '../common/buttons'

const LanguagePicker = () => {
  const dispatch = useDispatch()
  const lang = useSelector(s => s.settings.lang)

  return (
    <div>
      <ToggleButton
        onChange={() => {
          dispatch(setLang('fi'))
        }}
        isSelected={lang === 'fi'}
      >
        {t`Suomi`}
      </ToggleButton>

      <ToggleButton
        onChange={() => {
          dispatch(setLang('en'))
        }}
        isSelected={lang === 'en'}
      >
        {t`English`}
      </ToggleButton>
    </div>
  )
}

export default LanguagePicker
