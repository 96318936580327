import { useState, useCallback, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ThemeContext } from 'styled-components'

export const useInput = (def = '') => {
  const [val, setVal] = useState(def)

  const handleChange = useCallback(e => {
    setVal(e?.target?.value)
  }, [])

  useEffect(() => {
    setVal(def)
  }, [def])

  return [val, handleChange]
}

export const usePrev = val => {
  const ref = useRef(val)

  useEffect(() => {
    ref.current = val
  }, [val])

  return ref.current
}

export const useGoTo = path => {
  const history = useHistory()

  return useCallback(() => {
    history.push(path)
  }, [history])
}

export const useAction = (action, ...params) => {
  const dispatch = useDispatch()

  const dispatchAction = useCallback(() => {
    if (action && typeof action === 'function') dispatch(action(...params))
  }, [action, dispatch, params])

  return dispatchAction
}

export const useTheme = () => {
  const theme = useContext(ThemeContext)
  return theme
}

export const useGetValue = () => {
  const lang = useSelector(s => s.settings.lang)

  return useCallback(
    o => {
      return o?.[lang] ?? o?.fi ?? o?.en ?? o?.sv
    },
    [lang]
  )
}

export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
